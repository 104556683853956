import http from "../../utils/http"

/**
 * 获取商品分类树结构
 */
export function tree(params) {
    return http({
        url: "/api/goodscategory/tree",
        data: params
    })
}
export function treeByCustomized(params) {
    return http({
        url: "/api/Goodscategory/customized",
        data: params
    })
}
//获取定制商品配置

export function getOrderJson(params) {
    return http({
        url: "/api/goods/get_parameter?goods_id="+params,
    },0,'GET')
}
/**
 * 获取商品品牌信息
 * @param {Object} params 参数
 */
export function relevanceinfo(params) {
    return http({
        url: "/api/goodscategory/relevanceinfo",
        data: params
    })
}

/**
 * 获取商品分类信息
 * @param {Object} params 参数 category_id:1
 */
export function goodsCategoryInfo(params) {
    return http({
        url: "/api/goodscategory/info",
        data: params
    })
}
/**
 * 获取分类配置
 */
export function categoryConfig(params) {
    return http({
        url: "/pc/api/pc/categoryconfig",
        data: params
    })
}
