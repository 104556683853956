<template>

	<div id="elevator-floor" class="elevator">
		<div class="elevator-header">
			<div class="contact-us">
				<div class="contact-us-img"></div>
				<div class="contact-us-text">联系我们</div>
			</div>
			<div class="service-panel-wrapper">
				<div class="service-panel">
					<div class="box">
						<a target="_blank" href="https://work.weixin.qq.com/kfid/kfc557e2ddd109fc5f9">
							<div class="desc">企业采购在线客服</div>
							<div class="inquiry"><i></i>点击咨询</div>
						</a>
						<a target="_blank" href="https://work.weixin.qq.com/kfid/kfc51c9e952996915c7">
							<div class="desc">芒果财税在线客服</div>
							<div class="inquiry"><i></i>点击咨询</div>
						</a>
					</div>
					<div class="box">
						<div class="desc">企业采购热线</div>
						<div class="phone">400-6907099<br>13540567067<br>18602807567</div>
					</div>
					<div class="box">
						<div class="qr-code">
							<img src="https://shops.scxby.cn/upload/common/images/qyecode/code.jpg" alt="二维码">
						</div>
						<div class="qr-text">添加企业福利官微信<br>享受每日低价福利
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="elevator-main">
			<div class="jdb-elevator">
				<div id="" class="jdb-elevator-box ">
					<div class="jdb-elevator-item">采购<br>专属购</div>
					<div class="line"></div>
				</div>
				<div id="" class="jdb-elevator-box ">
					<a href="/cart">
						<div class="jdb-elevator-item">购物车</div>
						<div class="line"></div>
					</a>
				</div>
				<div id="" class="jdb-elevator-box ">
					<a href="/member/order_list">
						<div class="jdb-elevator-item">我的订单</div>
						<div class="line"></div>
					</a>

				</div>
				<div id="" class="jdb-elevator-box ">
					<a href="/member/collection">
						<div class="jdb-elevator-item">我的关注</div>
						<div class="line"></div>
					</a>
				</div>
			</div>
			<div class="back-top" @click="toTop()">
				<div class="jdb-elevator-item">
					<div class="icon"></div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import {
		shopServiceOpen
	} from "@/api/website.js"
	import servicerMessage from "@/components/message/servicerMessage";
	export default {
		props: {},
		data() {
			return {
				visible: false,
				hackReset: false,
				serverType: 'disable',
				serverThird: ''
			}
		},
		components: {
			servicerMessage
		},
		computed: {
			...mapGetters(["cartCount", "siteInfo", 'member']),
			logined: function() {
				return this.member !== undefined && this.member !== "" && this.member !== {}
			}
		},
		created() {
			this.shopServiceOpen()
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll)
		},
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScroll)
		},
		watch: {},
		methods: {
			handleScroll() {
				this.visible = window.pageYOffset > 300
			},
			shopServiceOpen() {
				shopServiceOpen().then((res) => {
					if (res.code == 0) {
						if (res.data.type == 'third') {
							this.serverType = res.data.type
							this.serverThird = res.data.third
						} else if (res.data.type == 'system') {
							this.serverType = res.data.type
							this.serverThird = ''
						}
					}
				})
			},
			toTop() {
				let timer = setInterval(function() {
					let osTop = document.documentElement.scrollTop || document.body.scrollTop
					let ispeed = Math.floor(-osTop / 5)
					document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
					this.isTop = true
					if (osTop === 0) {
						clearInterval(timer)
					}
				}, 20)
			},
			// 打开客服弹窗
			showServiceFn() {
				if (this.logined) {
					if (this.serverType == 'third') {
						window.open(this.serverThird, "_blank");
					} else if (this.serverType == 'system') {
						this.hackReset = true;
						this.$refs.servicerMessage.show()
					}
				} else {
					this.$message({
						message: "您还未登录",
						type: "warning"
					})
				}

			},
		},
	}
</script>

<style scoped lang="scss">
	.elevator {
		position: fixed;
		left: 50%;
		bottom: 100px;
		margin-left: 615px;
		width: 80px;
		z-index: 100
	}

	.elevator .elevator-main {
		border-radius: 8px;
		overflow: hidden
	}

	.elevator .back-top {
		background-color: #fff;
		cursor: pointer
	}

	.elevator .back-top .icon {
		width: 15px;
		height: 8px;
		background: 50%/contain url(https://img14.360buyimg.com/imagetools/jfs/t1/191795/24/32617/628/6413d692F3fd67b81/f79076fce36fb17c.png) no-repeat
	}

	.elevator .back-top:hover {
		background-color: #ff547b
	}

	.elevator .back-top:hover .icon {
		background-image: url(https://img13.360buyimg.com/imagetools/jfs/t1/91639/38/36278/479/641b0e46F8a874e5e/706e0419f1456e3e.png)
	}

	.elevator-header {
		position: relative;
		margin-bottom: 10px;
		cursor: pointer
	}

	.elevator-header .contact-us {
		position: relative;
		overflow: hidden;
		border-radius: 40px 40px 8px 8px
	}

	.elevator-header .contact-us-img {
		width: 80px;
		height: 160px;
		background: 50%/contain url(https://shops.scxby.cn/upload/common/images/qyecode/headers.jpg) no-repeat;
		border-radius: 40px 40px 0 0
	}

	.elevator-header .contact-us-text {
		position: absolute;
		width: 100%;
		bottom: 0;
		width: 80px;
		height: 38px;
		line-height: 38px;
		border-radius: 8px;
		font-size: 14px;
		font-weight: 700;
		background: #fff;
		color: #ff547b;
		text-align: center
	}

	.elevator-header .service-panel-wrapper {
		display: none;
		position: absolute;
		right: 80px;
		top: 0
	}

	.elevator-header .service-panel {
		width: 180px;
		padding: 8px;
		margin-right: 8px;
		border-radius: 8px;
		background: #fff
	}

	.elevator-header .service-panel .box {
		margin-bottom: 8px;
		border-radius: 4px;
		text-align: center;
		background: #f8f8f8
	}

	.elevator-header .service-panel .box:last-child {
		margin-bottom: 0
	}

	.elevator-header .service-panel .desc {
		padding: 14px 0 8px;
		color: rgba(0, 0, 0, .45);
		font-size: 14px;
	}

	.elevator-header .service-panel .inquiry {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-align: center;
		align-items: center;
		padding-bottom: 15px;
		font-size: 14px;
		font-weight: 700;
		color: #ff547b;
		cursor: pointer
	}

	.elevator-header .service-panel .inquiry i {
		display: inline-block;
		width: 19px;
		height: 18px;
		margin-right: 5px;
		background: 50%/contain url(https://img11.360buyimg.com/imagetools/jfs/t1/74187/33/25430/661/6414062aF4ce707fb/e6b520e82614a8f5.png) no-repeat
	}

	.elevator-header .service-panel .inquiry:hover {
		color: #ff547b
	}

	.elevator-header .service-panel .inquiry:hover i {
		background-image: url(https://img10.360buyimg.com/imagetools/jfs/t1/146747/17/35952/669/6413d692Ff4efa560/eda9e30a52e7fdc9.png)
	}

	.elevator-header .service-panel .phone {
		padding-bottom: 16px;
		font-size: 14px;
		font-family: JDZhengHT;
		color: rgba(0, 0, 0, .85)
	}

	.elevator-header .service-panel .qr-code {
		padding: 8px
	}

	.elevator-header .service-panel .qr-code img {
		width: 148px;
		height: 148px
	}

	.elevator-header .service-panel .qr-text {
		padding-bottom: 12px;
		line-height: 15px;
		white-space: pre-line;
		color: rgba(0, 0, 0, .45);
		font-size: 14px;
	}

	.elevator-header:hover .contact-us-text {
		color: #fff;
		background-color: #ff547b
	}

	.elevator-header:hover .service-panel-wrapper {
		display: block
	}

	.jdb-elevator-box {
		padding: 0 10px;
		background-color: #fff;
		color: rgba(0, 0, 0, .85);
		cursor: pointer
	}

	.jdb-elevator-box .line {
		width: 60px;
		height: 1px;
		background: linear-gradient(270deg, #fff, #eee 18.50162572%, #eee 81.88674133%, #fff)
	}

	.jdb-elevator-box.active {
		color: #ff547b
	}

	.jdb-elevator-box:hover {
		background-color: #ff547b;
		color: #fff
	}

	.jdb-elevator-box:hover .line {
		background: #ff547b
	}

	.jdb-elevator-box:hover a {
		color: #fff
	}

	.jdb-elevator-item {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-direction: column;
		flex-direction: column;
		height: 60px;
		line-height: 19px;
		font-size: 14px;
		text-align: center;
		white-space: pre-line
	}
</style>