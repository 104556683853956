<template>
	<div>
		<div class="header-top">
			<div class="top-content">

				<div class="top-left">
					<span  style="font-size: 14px;">
							北洋商城
					</span>
				</div>
				<div class="top-right">
					<router-link v-if="!logined" to="/login">您好，请登录</router-link>
					<div v-if="logined" class="member-info">
						<router-link to="/member/index">{{ member.nickname || member.username }}</router-link>
						<span @click="logout">退出</span>
					</div>

					<router-link v-if="!logined" to="/register">免费注册</router-link>

					<router-link to="/cms/help">帮助中心</router-link>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex"
	import {
		getToken
	} from "@/utils/auth"

	export default {
		props: {},
		data() {
			return {}
		},
		created() {
			this.$store.dispatch("site/qrCodes")
			this.$store.dispatch("member/member_detail")
			this.$store.dispatch("site/defaultFiles")
			this.$store.dispatch("site/addons")
		},
		mounted() {},
		watch: {},
		methods: {
			logout() {
				this.$store.dispatch("member/logout")
			}
		},
		components: {},
		computed: {
			...mapGetters(["wapQrcode", "member", "addonIsExit", "city"]),
			qrcode: function() {
				return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
			},
			logined: function() {
				return this.member !== undefined && this.member !== "" && this.member !== {}
			}
		}
	}
</script>

<style scoped lang="scss">
	.header-top {
		width: 100%;
		height: 56px;
		font-size: 12px;
		background-color: #2e58e6;

		.el-dropdown {
			font-size: $ns-font-size-sm;
		}

		.top-content {
			width: $width;
			height: 100%;
			margin: 0 auto;


			.top-left {
				height: 100%;
				float: left;
				height: 46px;
				line-height: 56px;
				background: url('../../assets/images/header-top-left.png');

				width: 110px;
				text-align: center;
				color: #cf3a2a;
				margin-top: 10px;
				.change-city {
					cursor: pointer;
					margin-left: 5px;

					&:hover {
						color: $base-color;
						border-color: $base-color;
					}
				}
			}

			.top-right {
				height: 100%;
				float: right;
				font-size: $ns-font-size-sm;
				color: $ns-text-color-black;

				a {
					float: left;
					line-height: 56px;
					padding: 0 10px;
					color: #fff;

				}

				div {
					float: left;
					height: 100%;
					margin-left: 10px;
					cursor: pointer;
					line-height: 56px;
					padding: 0 5px;

					&.member-info {
						margin-left: 0;

						span:first-child {
							margin-right: 10px;
						}
					}

					span:hover {
						color: $base-color;
					}

					&.el-dropdown:hover {
						background-color: #fff;
					}
				}
			}
		}
	}

	.mobile-qrcode {
		padding: 10px 0;
	}

	.router-link-active {
		color: $base-color;
	}
</style>