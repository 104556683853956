<template>
    <el-container class="creatContainer">
        <div :class="classObj" class="app-wrapper">
            <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
            <sidebar @sendOpenMenu="sendOpenMenu" />
            <div :class="{ hasTagsView: needTagsView, 'openMenu-header': openMenu }" class="main-container">
                <div :class="{ 'fixed-header': fixedHeader, 'openMenu-header': openMenu }">
                    <navbar />
                    <tags-view v-if="needTagsView" />
                </div>
                <app-main />
                <right-panel v-if="showSettings">
                    <settings />
                </right-panel>
            </div>
        </div>
    </el-container>
</template>

<script>
import RightPanel from '@/components/RightPanel'
import AppMain from './components/AppMain.vue'
import Sidebar from './components/Sidebar/index.vue'
import TagsView from './components/TagsView/index.vue'
import Navbar from './components/Navbar.vue'
import Settings from './components/Settings/index.vue'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'

export default {
    name: 'Layout',
    components: {
        AppMain,
        Navbar,
        RightPanel,
        Settings,
        Sidebar,
        TagsView
    },
    mixins: [ResizeMixin],
    computed: {
        ...mapState({
            sidebar: state => state.app.sidebar,
            device: state => state.app.device,
            showSettings: state => state.settings.showSettings,
            needTagsView: state => state.settings.tagsView,
            fixedHeader: state => state.settings.fixedHeader
        }),
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                withoutAnimation: this.sidebar.withoutAnimation,
                mobile: this.device === 'mobile',
                openMenu: !this.sidebar.opened && this.openMenu
            }
        }
    },
    data: () => {
        return {
            openMenu: false
        }
    },
    methods: {
        handleClickOutside() {
            this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
        },
        sendOpenMenu(val) {
            this.openMenu = val
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/mixin.scss";
@import "../styles/variables.scss";

.creatContainer {
    box-sizing: border-box;
}

.main-container {
    margin-left: 0px !important;
}

.app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;

    &.mobile.openSidebar {
        position: fixed;
        top: 0;
    }
}

.drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
}

.fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
}

.hideSidebar .fixed-header {
    width: calc(100% - 54px)
}

.openMenu .fixed-header {
    width: calc(100% - 254px)
}

.openMenu .sidebar-container {
    width: 254px !important;
}

.mobile .fixed-header {
    width: 100%;
}

.openMenu-header {
    width: calc(100% - 410px)
}
</style>
