<template>
	<div class="menusty" v-if="isCollapse">
		
		<el-menu :default-active="defaultActive" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" @select="selectMenu" router>
			<el-submenu :index="path+'?categoryId=' + item.category_id + ''"
				v-for="item, index in goodsCategoryTree" :key="index" v-if="item.goods_list&&item.goods_list.length>0">
				<template slot="title">
					<span slot="title">{{ item.category_name }}</span>
				</template>
				<el-menu-item-group v-if="item.goods_list" v-for="i, j in item.goods_list" :key="j">
					<el-menu-item :index="path+'?categoryId=' + i.goods_id + ''">{{ i.goods_name }}</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item v-for="item, index in goodsCategoryTree" :key="index" v-if="!item.goods_list||item.goods_list.length==0"  :index="path+'?categoryId=' + item.category_id + ''">{{ item.category_name }}</el-menu-item>
		</el-menu>
	</div>
</template>
<script>

import { tree, categoryConfig,treeByCustomized } from '@/api/goods/goodscategory';

export default {
	props: {
		isCollapse: {
			type: Boolean,
			default: false
		},
		path:{
			type: String,
			default: null
		},
		defaultActive:{
			type: String,
			default: null
		}
	},
	data: () => {
		return {
			goodsCategoryTree: [],
			selectedCategory: -1,
			adList: [],
		}
	},
	created() {
		this.getTree()
	},
	mounted() {
		console.log(this.defaultActive,'defaultActive');
	},
	methods: {
		getTree() {
			treeByCustomized()
				.then(res => {
					if (res.code == 0 && res.data) {
						this.goodsCategoryTree = res.data || [];
						this.$store.dispatch('app/tree', this.goodsCategoryTree);
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		selectMenu(index,path){
			console.log(index,path);
		}
	}
};
</script>

<style lang="scss" scoped>
.menusty {
	width: 200px;
	z-index: 999;
}
</style>