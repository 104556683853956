<template>
  <div :class="{ 'has-logo': showLogo }" class="allNav">
    <div>
      <logo v-if="showLogo" :collapse="isCollapse" />
      <div class="sidebar-container">
        <el-scrollbar wrap-class="scrollbar-wrapper" id="menuOne">
          <el-menu :default-active="activeMenu" :collapse="isCollapse" :background-color="variables.menuBg"
            :text-color="variables.menuText" :unique-opened="false" :active-text-color="variables.menuActiveText"
            :collapse-transition="false" mode="vertical" @select="selectMenu">
            <sidebar-item v-for="route in permission_routes" :key="route.path" :item="route" :base-path="route.path" />
          </el-menu>
        </el-scrollbar>
      </div>
    </div>

    <sidebar-menu :isCollapse="openMenu" :defaultActive="activeOpenMenu" :path="path" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import SidebarMenu from './SidebarMenu'
export default {
  components: { SidebarItem, SidebarMenu, Logo },
  data: () => {
    return {
      openMenu: false,
      path: ''
    }
  },
  computed: {
    ...mapGetters([
      'permission_routes',
      'sidebar'
    ]),
    activeMenu() {
      const route = this.$route
      console.log(route, 'torrr');
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    activeOpenMenu() {
      const route = this.$route
      const { meta, fullPath } = route
      return fullPath
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  mounted() {
    let path = this.$route.path
    if (path == '/orderCreateByOfficial' || path == '/manualOrder' || path == '/orderCreate') {
      this.openMenu = true
      this.path = path
    } else {
      this.openMenu = false
    }
    this.$emit('sendOpenMenu', this.openMenu)
  },
  methods: {
    selectMenu(index, path) {
      if (index == '/orderCreateByOfficial' || index == '/manualOrder' || index == '/orderCreate') {
        this.openMenu = true
        this.path = index
      } else {
        this.openMenu = false
      }
      this.$emit('sendOpenMenu', this.openMenu)
    }
  }
}
</script>
<style scoped>
.allNav {
  display: flex;
  /* height: 100vh; */
}

::v-deep #menuOne .el-menu-item.is-active::before {
  position: absolute;
  right: 0;
  bottom: -10px;
  z-index: 2;
  display: block;
  width: 10px;
  height: 10px;
  background-image: url('https://www.sd2000.com/Content/img/swallow.svg');
  background-size: 100%;
  content: "";
  transform: rotate(90deg);
  color: #222 !important;
}

::v-deep #menuOne .el-menu-item.is-active::after {
  position: absolute;
  top: -10px;
  right: 0;
  z-index: 2;
  display: block;
  width: 10px;
  height: 10px;
  background-image: url('../../../assets/images/order/swallow.svg');
  background-size: 100%;
  content: "";
  transform: rotate(180deg);
  color: #222 !important;
}

::v-deep #menuOne .el-menu-item.is-active {
  border-left: 4px solid #ffc400;
  background-color: #FFF !important;
  color: #222 !important;
}

::v-deep #menuOne .el-menu-item:hover {
  background: #312F29 !important;
}

::v-deep #menuOne .el-menu-item.is-active:hover {
  background-color: #FFF !important;
}

::v-deep .sidebar-container a {
  display: inline !important;
}
</style>
