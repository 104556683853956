import OrderCreateLayout from "../../layout/orderCreate"

const orderCreateRoutes = [
    {
        path: "/orderCreate/index",
        name: "orderCreate_index",
        meta: {
            module: "orderCreate",
            backgroundColor: "#fff",
            title: '批量下单',
            icon: 'el-icon-s-order',
            noCache: true
        },
        component: () => import("@/views/orderCreate/index")
    }
]

export default [{
    path: "/orderCreate",
    component: OrderCreateLayout,
    children: [{
        path: "/orderCreate",
        name: "orderCreate",
        meta: {
            module: "orderCreate",
            backgroundColor: "#fff",
            title: '批量下单',
            icon: 'el-icon-s-order',
            auth: true,
        },
        component: () => import("@/views/orderCreate/index")
    }]

}, {
    // path: "/customized",
    path: "/orderCreateByOfficial",
    component: OrderCreateLayout,
    redirect: "/orderCreateByOfficial",
    children: [{
        path: "/orderCreateByOfficial",
        name: "orderCreateByOfficial",
        meta: {
            module: "orderCreateByOfficial",
            backgroundColor: "#fff",
            title: '官网下单',
            icon: 'el-icon-s-order',
            // auth: true,
        },
        component: () => import("@/views/orderCreate/orderCreateByOfficial")
    }]
}, {
    path: "/manualOrder",
    component: OrderCreateLayout,
    children: [{
        path: "/manualOrder",
        name: "manualOrder",
        meta: {
            module: "manualOrder",
            backgroundColor: "#fff",
            title: '人工接单',
            icon: 'el-icon-s-order',
            auth: true,
        },
        component: () => import("@/views/orderCreate/manualOrder")
    }]
}]